<template>
  <div class="main">
    <b-modal
      id="md-change-product"
      hide-footer
      title="Đổi sản phẩm mới"
      no-close-on-backdrop
      size="lg"
      static
    >
      <b-row>
        <b-col class="bg-light-warning px-6 py-3 rounded-xl mr-7 mb-7">
          <i class="fas fa-exclamation-circle text-warning ml-2 icon-size"></i>
          Lưu ý: Chỉ phù hợp đổi các sản phẩm thuộc cùng danh mục <span class="font-weight-bold">{{cacheProduct.oldProductCategoryName}}</span> với sản phẩm bảo hành <span class="font-italic font-weight-bold">{{cacheProduct.oldProductName}}</span>
        </b-col>
      </b-row>
      <b-row class="mb-5">
        <b-col
          cols="3"
          class="pr-0"
        >
          <b-form-select
            class="select-style"
            v-model="productSearch.searchType"
            :options="[
              {
                id: 1,
                name: 'Tìm sản phẩm',
              },
              {
                id: 2,
                name: 'Tìm sản phẩm imei',
              },
            ]"
            size="sm"
            value-field="id"
            text-field="name"
          ></b-form-select>
        </b-col>
        <b-col
          cols="9"
          class="pl-0"
        >
          <treeselect
            v-if="dataReady"
            :load-options="loadOptionProducts"
            :defaultOptions="defaultProductOptions"
            :multiple="false"
            :async="true"
            :placeholder="productSearch.placeHolder"
            :cache-options="false"
            :clearOnSelect="true"
            retryText="Thử lại..."
            retryTitle="Nhấp thử lại"
            searchPromptText="Nhập để tìm kiếm..."
            loadingText="Đang tải..."
            noResultsText="Không có kết quả"
            openDirection="bottom"
            @select="onSelectProduct"
            :always-open="isOpenProductSearch"
            @search-change="onSearchChange"
          >
            <label
              slot="option-label"
              slot-scope="{ node, labelClassName }"
              :class="labelClassName"
            >
              <template>
                <div>
                  <b class="text-primary">( {{ convertPrice(node.raw.sellingPrice) }} )</b>
                  <b :class="
                      node.raw.quantityInStock > 0
                        ? 'text-success'
                        : 'text-danger'
                    ">
                    ( TCB:{{ node.raw.quantityInStock }} )
                  </b>

                  {{ node.raw.label }}
                  <span
                    v-if="
                      node.raw.imeiCode && node.raw.imeiCode.trim().length > 0
                    "
                    class="text-warning"
                  >
                    - IMEI:
                    {{ node.raw.imeiCode }}
                  </span>
                </div>
              </template>
            </label>
          </treeselect>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <table class="table table-bordered table-vertical-center table-hover">
            <thead>
              <tr>
                <th class="header-table">Sản phẩm</th>
                <th class="header-table">Số lượng</th>
                <th class="header-table">Giá</th>
                <th class="header-table">Thành tiền</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <p class="mb-0">
                    {{  exchangeProduct.productName }}
                  </p>
                  <p
                    v-if="exchangeProduct.newProductType === 2"
                    class="text-success mb-0"
                  >
                    IMEI: {{ exchangeProduct.newImeiCode }}
                  </p>
                  <b-input-group
                    class="row col-8 btn-imei"
                    v-if="exchangeProduct.newProductType === 2 
                    || currentProductType === 2"
                  >
                    <div>
                      <div
                        class="mt-2"
                        style="cursor: pointer"
                        @click="linkToListImei"
                      >
                        <span class="text-primary mb-0">Danh sách IMEI</span>
                      </div>
                    </div>
                  </b-input-group>
                </td>
                <td>
                  <div>
                    <b-form-input
                      tabindex="1"
                      @input="onChangeQuantity"
                      v-model="exchangeProduct.quantity"
                      type="text"
                      size="sm"
                      placeholder="Nhập SL"
                      :disabled="exchangeProduct.newProductType === 2"
                    ></b-form-input>
                  </div>
                </td>
                <td>
                  {{
                    exchangeProduct.productPrice
                      ? formatPrice(exchangeProduct.productPrice)
                      : 0
                  }}
                </td>
                <td>
                  {{
                    exchangeProduct.productPrice
                      ? formatPrice(exchangeProduct.totalPrice)
                      : 0
                  }}
                </td>
              </tr>
            </tbody>
          </table>
        </b-col>
      </b-row>

      <b-overlay
        :show="onLoadingList || isBusy"
        rounded
        opacity="0.6"
        class="d-inline-block mr-3"
      >
        <template #overlay>
          <div class="d-flex align-items-center">
            <b-spinner
              variant="light"
              small
              type="grow"
            ></b-spinner>
          </div>
        </template>
        <b-button
          variant="primary"
          size="sm"
          @click="onSave"
        >
          <strong>Đổi sản phẩm</strong>
        </b-button>
      </b-overlay>

      <b-button
        style="width: 80px"
        variant="secondary"
        size="sm"
        @click="$bvModal.hide('md-change-product')"
      >
        <strong>Hủy</strong>
      </b-button>
    </b-modal>
  </div>
</template>

<script>
import { EventBus } from '@/core/services/event-bus';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import { ASYNC_SEARCH } from '@riophae/vue-treeselect';
import { PRODUCT_TYPE } from '@/utils/enum';
import ApiService from '@/core/services/api.service';
import { cloneDeep, makeToastFaile, formatPrice } from '@/utils/common';
import { convertPrice } from '@/utils/common';
import debounce from 'debounce';
import { TIME_TRIGGER } from '@/utils/constants';
import { mapState, mapActions } from 'vuex';

const SEARCH_TYPE = {
  PRODUCT: 1,
  IMEI: 2,
};

export default {
  components: {
    Treeselect,
  },
  created() {
    EventBus.$on('popup-product-common', this.popupProductCommon);
  },
  mounted() {
    this.$nextTick(() => {
      this.defaultProductOptions = null;
    });
  },
  data() {
    return {
      dataReady: false,
      defaultProductOptions: [],
      isOpenProductSearch: false,
      PRODUCT_TYPE,
      searchProduct: '',
      productSearch: {
        searchType: SEARCH_TYPE.PRODUCT,
        placeHolder: 'Tìm kiếm theo tên, mã, mã vạch sản phẩm',
        searchName: null,
      },
      onLoadingList: false,
      isBusy: false,
      storeId: null,
      currentProductType: null,
      productSearchTemp: null,
      cacheProduct: {
        oldId: null,
        oldProductId: null,
        oldProductCategory: null,
      },
    };
  },
  computed: {
    ...mapState({
      exchangeProduct: (state) => state.exchangeProduct.exchangeProduct,
    }),
  },
  methods: {
    ...mapActions(['updateExchangeProduct']),
    formatPrice,
    linkToListImei: function () {
      this.$emit('linkToListImei', {
        ...this.exchangeProduct,
        isMainProduct: false,
      });
    },
    onChangeQuantity() {
      this.exchangeProduct.totalPrice =
        this.exchangeProduct.quantity * this.exchangeProduct.productPrice;
    },
    convertPrice,
    onSearchChange(searchQuery) {
      if (searchQuery.length > 1 && !this.isOpenProductSearch) {
        this.isOpenProductSearch = true;
      } else if (searchQuery.length === 0) {
        this.isOpenProductSearch = false;
      }
    },
    onSelectProduct(node) {
      this.isOpenProductSearch = false;
      const itemSelected = cloneDeep(node);
      const product = {
        isMainProduct: false,
        oldId: this.cacheProduct.oldId,
        oldProductId: this.cacheProduct.oldProductId,
        newId: itemSelected.id,
        newProductId: itemSelected.productId,
        newImeiCode: itemSelected.imeiCode || '',
        productName: itemSelected.productName,
        productCode: itemSelected.productCode,
        productPrice: itemSelected.sellingPrice,
        totalPrice: 1 * itemSelected.sellingPrice,
        productType: itemSelected.productType,
        newProductType: itemSelected.productType,
        originalProductType: itemSelected.productType,
        quantity: 1,
        sellingPrice: itemSelected.sellingPrice,
      };
      this.updateExchangeProduct(product);
    },
    fetchProduct(textSearch) {
      this.listProduct = [];

      const params = {
        searchProduct: textSearch ? textSearch.trim() : null,
        storeId: this.storeId || null,
        productCategory: this.cacheProduct.oldProductCategory || null,
      };

      return ApiService.query('productSearch', { params }).then((response) => {
        return response.data;
      });
    },
    fetchProductImei(textSearch) {
      this.listProduct = [];
      const storeId = this.storeId || null;

      if (!storeId) {
        makeToastFaile('Chưa chọn cửa hàng');
        return [];
      }

      const params = {
        imeiCode: textSearch ? textSearch.trim() : null,
        storeId: storeId,
      };

      return ApiService.query(`productSearch/search-by-imei`, { params }).then(
        (response) => {
          return response.data;
        },
      );
    },
    searchProductAPI: debounce(function (searchQuery, type) {
      switch (type) {
        case SEARCH_TYPE.PRODUCT:
          return this.fetchProduct(searchQuery, type);
        case SEARCH_TYPE.IMEI:
          return this.fetchProductImei(searchQuery, type);
      }
      return [];
    }, TIME_TRIGGER),
    searchProductAPINotFilterCategory: debounce(function (searchQuery, type) {
      if (type === SEARCH_TYPE.IMEI) {
        return {
          data: [],
        };
      }

      const params = {
        searchProduct: searchQuery ? searchQuery.trim() : null,
        storeId: this.storeId || null,
      };

      return ApiService.query('productSearch', { params }).then((response) => {
        return response.data;
      });
    }, TIME_TRIGGER),
    async loadOptionProducts({ action, searchQuery, callback }) {
      if (action === ASYNC_SEARCH) {
        this.productSearchTemp = searchQuery;
        const type = this.productSearch.searchType;
        const [result, resultNotFilterCategory] = await Promise.all([
          this.searchProductAPI(searchQuery, type),
          this.searchProductAPINotFilterCategory(searchQuery, type),
        ]);

        const filteredProducts = result.data || [];
        const productsNotFilterCategory = resultNotFilterCategory.data || [];

        const products = filteredProducts.length
          ? filteredProducts
          : productsNotFilterCategory;

        const optionProduct = products.map((element) => {
          if (type === PRODUCT_TYPE.PRODUCT) {
            return {
              id: this.$uuid.v4(),
              label: element.productName,
              isDisabled: element.quantityInStock <= 0,
              ...element,
            };
          } else {
            return {
              id: this.$uuid.v4(),
              label: element.productName,
              imeiCode: element.imeiCode,
              isDisabled: element.quantityInStock <= 0,
              ...element,
            };
          }
        });

        callback(null, optionProduct);
      }
    },
    popupProductCommon(payload) {
      const { storeId, product, defaultProductOptions } = payload;
      this.storeId = storeId;
      this.cacheProduct.oldId = product.id || null;
      this.cacheProduct.oldProductId = product.productId || null;
      this.cacheProduct.oldProductCategory = product.productCategory || null;
      this.cacheProduct.oldProductName = product.productName || null;
      this.cacheProduct.oldProductCategoryName =
        product.productCategoryName || null;
      this.defaultProductOptions = defaultProductOptions;
      this.dataReady = true;
      this.$bvModal.show('md-change-product');
    },
    onSave() {
      if (
        this.exchangeProduct.newProductType === 2 &&
        !this.exchangeProduct.newImeiCode
      ) {
        makeToastFaile('Vui lòng chọn mã IMEI');
        return;
      }
      this.$bvModal.hide('md-change-product');
      this.$emit('changeWarrantyProduct', this.exchangeProduct);
    },
  },
};
</script>

<style lang="scss" scoped>
.main ::v-deep {
  .modal-backdrop {
    opacity: 0.5;
  }

  .vue-treeselect__control {
    height: 33px;
  }
  .header-table {
    color: rgb(24, 28, 50);
  }
}
</style>
